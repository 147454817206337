import { useContext } from "react";
import { Link } from "react-router-dom";
import BgLiveImage from "../Components/Ui/BgLiveImage";
import overlayContext from "../Context/overlayContext";

export default function Hero() {
	const { showOverlay, setShowoverlay, overlayData, setOverlayData } = useContext(overlayContext);

	function easterEgg () {
		setShowoverlay('easterEgg')
	}

    return (
        <section className="hero-content full-size flex-box stretch bg-4">
			<div className="bg-image match-header" style={{'display': 'none'}}>
				<figure className="image-box">
					<img srcSet={`${process.env.REACT_APP_ENDPOINT}images/bg-small-790.jpg 760w,
						${process.env.REACT_APP_ENDPOINT}images/bg-small-1080.jpg 1080w,
						${process.env.REACT_APP_ENDPOINT}images/bg-small.jpg 1600w`} sizes="(max-width: 1680px) 100vw, 1680px" src={process.env.REACT_APP_ENDPOINT+"images/bg-small.jpg"} />
				</figure>
			</div>

			<div className="bg-image live-header">
				<figure className="image-box">
					<BgLiveImage />
				</figure>
			</div>

			<div className="item-box live-header">
				<div className="content-box txt-center pd-12">
                        <figure className="image-box max-w">
                            <img 
							style={{width: '15em', height: '15em'}}
							src={process.env.REACT_APP_ENDPOINT+"images/intro-icon.png"} 
							usemap="#image-map" />
							<map name="image-map">
								<area coords="79,78,108,111" shape="rect" onClick={() => easterEgg()} />
							</map>
                        </figure>
                        <h1 className="cl-white f-80">Use our Matchmaker to find your Business Partner!</h1>
                        <div className="button-box">
							<Link to="/matchmaking" className="button call-to-action matchmaking-link" >
								<span className="matchmaking-label">Join Now!</span><span className="icon-box"><i className="icon"></i></span>
							</Link>
                    </div>
                </div>
			</div>
		</section>
    );
}